import React, { useState } from 'react';
import { api_host } from '../../utils/settings';
import { ErrorsContext, IdSessionContext, UserSessionContext } from "../App";

interface ReviewPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (rating: number, comment: string) => void;
}

export const ReviewPopup: React.FC<ReviewPopupProps> = ({ isOpen, onClose, onSubmit }) => {
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userSession } = React.useContext(UserSessionContext);
  const { idSession, setIdSession } = React.useContext(IdSessionContext);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    if (!userSession?.user.id || !idSession?.id) {
      setError("Please login");
      return;
    }
    const sessionId = idSession?.id;
    try {
      const response = await fetch(`${api_host}/${userSession.user.id}/${sessionId}/submit_review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rating, comment }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit review');
      }

      onSubmit(rating, comment);
      onClose();
    } catch (error) {
      console.error('Error submitting review:', error);
      setError('Failed to submit review. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6">Leave a Review</h2>
        <div className="mb-6">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              onClick={() => setRating(star)}
              className={`text-3xl ${rating >= star ? 'text-yellow-400' : 'text-gray-300'} mr-1`}
            >
              ★
            </button>
          ))}
        </div>
        <textarea
          className="w-full p-3 border rounded mb-6 resize-none"
          placeholder="Your comment (optional)"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows={4}
        />
        {error && <p className="text-red-500 mb-6">{error}</p>}
        <div className="flex justify-end">
          <button
            className="bg-gray-300 text-gray-700 px-6 py-2 rounded mr-4 hover:bg-gray-400 transition duration-300"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-2 rounded font-semibold hover:from-blue-600 hover:to-blue-700 transition duration-300 transform hover:scale-105"
            onClick={handleSubmit}
            disabled={rating === 0 || isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};
// react component the renders popup menu

import React, { Dispatch, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SetLanguageContext } from "../contexts/LanguageContext";
import { translations } from "../lang/texts";
import { Globe } from "./micro/icons";

type MenuProps = {
  isShown: boolean;
  setIsShown: Dispatch<boolean>;
};

export const Menu = (props: MenuProps) => {
  const setLanguage = useContext(SetLanguageContext);
  const navigate = useNavigate();
  const { isShown, setIsShown } = props;

  const onClickLink = (path: string) => {
    navigate(path);
    setIsShown(!isShown);
  };

  const langToFlag = (lang: string) => {
    if (lang === "en") {
      return "gb";
    }
    if (lang === "cs") {
      return "cz";
    }
    return lang;
  }

  return (
    <>
      <div
        className={
          (isShown ? "right-6 " : "-right-[100%] ") +
          "z-[99] transition-all ease-out divide-y bg-primary fixed top-24 flex flex-col justify-left items-start mb-6 max-h-70vh overflow-scroll"
        }
      >
        <div
          onClick={() => onClickLink("/landing")}
          className="w-full text-sm text-white flex justify-end"
        >
          <div className="px-8 py-2">
            Choose your Language
            <Globe extraClasses="inline ml-2" />
          </div>
        </div  >
        {Object.keys(translations).map((lang, index) => (
          <button
            onClick={() => setLanguage(translations[lang])}
            className="w-full text-xl font-bold text-white flex justify-end"
          >
            <div className="px-8 py-2">{lang.toUpperCase()} <span className={"ml-2 fi fi-" + langToFlag(lang)}></span></div>
          </button>
        ))}
        <button
          onClick={() => onClickLink("/landing")}
          className="w-full text-xl font-bold text-white flex justify-end"
        >
          <div className="px-8 py-2">Start Over</div>
        </button  >
        <a
          href="https://www.selfideed.com/terms-conditions"
          target="_blank"
          className="w-full text-xl font-bold text-white flex justify-end"
        >
          <div className="px-8 py-2">Terms</div>
        </a>
        <a
          href="https://www.selfideed.com/privacy-policy"
          target="_blank"
          className="w-full text-xl font-bold text-white flex justify-end"
        >
          <div className="px-8 py-2">Privacy Policy</div>
        </a>
        <a
          href="https://www.selfideed.com"
          target="_blank"
          className="w-full text-xl font-bold text-white flex justify-end"
        >
          <div className="px-8 py-2">About</div>
        </a>
      </div>
    </>
  );
};

import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import { translations } from "../lang/texts";
import { Menu } from "./Menu";
import { BurgerMenu, ChevronLeft, ThreeDots } from "./micro/icons";
import { Title } from "./Title";

export const Navbar = () => {
  const language = useContext(LanguageContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isBackVisible, setIsBackVisible] = React.useState(false);
  const [isShown, setIsShown] = React.useState(false);

  useEffect(() => {
    console.log("location: ", location);
    location.pathname === "/landing" ? setIsBackVisible(false) : setIsBackVisible(true);
    location.pathname === "/result" ? setIsBackVisible(false) : setIsBackVisible(true);
  }, [location.pathname]);

  const onMenuClick = () => {
    console.log("menu click");
    setIsShown(!isShown);
  };
  return (
    <>
      <div className="bg-lightblue flex justify-between h-24 items-center fixed top-0 left-0 w-full z-[99]">
        <span onClick={() => navigate(-1)} className={"text-primary mx-5 " + (isBackVisible ? "" : " invisible")}>
          <ChevronLeft stroke={3.5} />
        </span>
        <div className="flex flex-col justify-center items-center">

          <h1 className="text-3xl font-bold text-primary">
            <span className="text-primary">Self</span>
            <span className="text-grayish">ID</span>
            <span className="text-primary">eed</span>
          </h1>
          <h2 className="text-grayish font-bold text-s text-center">{language.common_subtitle}</h2>
        </div>
        <span onClick={onMenuClick} className="mx-5 text-primary">
          <BurgerMenu stroke={2.5} extraClasses="w-8 h-8" />
        </span>
      </div>
      {/* background shadow */}
      <div onClick={() => setIsShown(!isShown)} className={"transition-all ease-in-out fixed top-0 left-0 w-full h-screen z-[70] " + (isShown ? "bg-black bg-opacity-50" : "hidden")}></div>
      <Menu isShown={isShown} setIsShown={setIsShown} />
      <Title />
    </>
  );
};

import React from 'react';
import { FaWhatsapp, FaFacebookF, FaInstagram, FaXTwitter } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';

interface ThankYouConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ThankYouConfirmation: React.FC<ThankYouConfirmationProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const shareUrl = window.location.origin;
  const shareText = 'Check out this awesome ID photo app!';

  const handleShare = (platform: string) => {
    let url = '';
    switch (platform) {
      case 'whatsapp':
        url = `https://wa.me/?text=${encodeURIComponent(`${shareText} ${shareUrl}`)}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      // case 'instagram':
      //   // Instagram doesn't have a direct share URL, so we'll copy to clipboard
      //   navigator.clipboard.writeText(`${shareText} ${shareUrl}`);
      //   alert('Link copied to clipboard. You can now paste it in your Instagram story or post.');
      //   return;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'email':
        url = `mailto:?subject=${encodeURIComponent('Check out SelfIDeed')}&body=${encodeURIComponent(`${shareText} ${shareUrl}`)}`;
        break;
    }
    window.open(url, '_blank');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <h2 className="text-3xl font-bold mb-6">Thank You!</h2>
        <p className="text-lg mb-8">Feel free to share Selfideed with your friends:</p>
        <div className="flex justify-center space-x-4 mb-6">
          <button
            className="bg-green-500 hover:bg-green-600 text-white p-3 rounded-full transition duration-300 ease-in-out"
            onClick={() => handleShare('whatsapp')}
            aria-label="Share on WhatsApp"
          >
            <FaWhatsapp size={24} />
          </button>
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white p-3 rounded-full transition duration-300 ease-in-out"
            onClick={() => handleShare('facebook')}
            aria-label="Share on Facebook"
          >
            <FaFacebookF size={24} />
          </button>
          {/* <button
            className="bg-pink-600 hover:bg-pink-700 text-white p-3 rounded-full transition duration-300 ease-in-out"
            onClick={() => handleShare('instagram')}
            aria-label="Share on Instagram"
          >
            <FaInstagram size={24} />
          </button> */}
          <button
            className="bg-black hover:bg-gray-800 text-white p-3 rounded-full transition duration-300 ease-in-out"
            onClick={() => handleShare('twitter')}
            aria-label="Share on X (Twitter)"
          >
            <FaXTwitter size={24} />
          </button>
          <button
            className="bg-red-500 hover:bg-red-600 text-white p-3 rounded-full transition duration-300 ease-in-out"
            onClick={() => handleShare('email')}
            aria-label="Share via Email"
          >
            <MdEmail size={24} />
          </button>
        </div>
        <button
          className="w-full bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};